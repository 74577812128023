<template>
  <div class="page-banner swiper-container">
    <div class="swiper-wrapper">
      <a
        class="swiper-slide"
        :href="item.link ? item.link : 'javascript:;'"
        :style="{
              background: `url(${item.img}) no-repeat`,
              backgroundPosition: 'center'
           }"
        v-for="item in data"
        :key="item.id"
      >
        <div class="slider-content">
          <h2 class="slider-title">{{ item.title }}</h2>
          <h4 class="slider-desc">{{ item.desc }}</h4>
        </div>
      </a>
    </div>
    <div class="custom-swiper-button custom-swiper-button-prev">
      <i class="icon icon-prev"/>
    </div>
    <div class="custom-swiper-button custom-swiper-button-next">
      <i class="icon icon-next"/>
    </div>
    <div class="main-swiper-pagination swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {
  name: 'MainBanner',
  props: {
    data: {
      type: Array
    }
  },
  data() {
    return {
      swiper: null
    }
  },
  methods: {
    init() {
      this.swiper = new Swiper('.page-banner', {
        autoplay: true,
        loop: true,
        pagination: {
          el: '.main-swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-container {
  width: 100%;
  height: 600px;
  background: #CCCCCC;
  /deep/ .swiper-pagination-bullets {
    left: 50%;
    transform: translateX(-50%);
    width: unset;
    height: 20px;
    padding: 5px;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 3px;
      border-radius: 5px;
      background: rgba(255, 255, 255, .5);
      transition-duration: .3s;
      &:hover {
        background: rgba(255, 255, 255, .7);
      }
    }
    .swiper-pagination-bullet-active {
      background: #FFFFFF;
    }
  }
  .custom-swiper-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    border: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .2);
    color: #fff;
    transition-duration: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, .35);
    }
    .icon {
      font-size: 12px;
    }
  }
  .custom-swiper-button-prev {
    left: 20px;
  }
  .custom-swiper-button-next {
    right: 20px;
  }
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 600px;
      .slider-content {
        width: 1200px;
        margin: 220px auto 0;
        padding-left: 50px;
        color: #ffffff;
        .slider-title {
          width: 650px;
          font-size: 46px;
          font-weight: 600;
          margin-bottom: 40px;
        }
        .slider-desc {
          width: 588px;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
