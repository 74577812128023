<template>
  <section class="display-section-wrapper">
    <div class="display-section">
      <h2 class="section-title">{{ title }}</h2>
      <h4 class="section-desc">{{ desc }}</h4>
      <div class="section-content">
        <slot/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DisplaySection',
  props: {
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.display-section-wrapper {
  width: 100%;
  .display-section {
    width: 1200px;
    padding: 80px 0 60px 0;
    margin: 0 auto;
    .section-title {
      text-align: center;
      font-size: 28px;
      font-weight: 600;
    }
    .section-desc {
      text-align: center;
      margin-top: 14px;
      font-size: 16px;
    }
    .section-content {
      width: 100%;
      margin: 40px 0;
    }
  }
}
</style>
