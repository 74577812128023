<template>
  <div class="info-card">
    <router-link
      class="card-pic"
      :to="{ name: 'News', query: { type: option.id } }"
      :style="{
        background: `url(${option.img}) no-repeat`,
        backgroundSize: '100% 100%'
      }"
    >
      <img class="card-icon" v-lazy="option.icon"/>
      <h3 class="card-title">{{ option.title }}</h3>
    </router-link>
    <div class="card-content">
      <div class="info-list" v-if="list.length">
        <router-link
          class="list-item"
          v-for="item in list"
          :key="item.id"
          :to="{ name: 'NewsDetail', query: { id: item.id }}"
        >
          <div class="item-date">{{ item.date }}</div>
          <div class="in-one-line item-title">{{ item.title }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    option: {
      type: Object,
      default() {
        return {
          img: '',
          icon: '',
          title: ''
        }
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.info-card {
  width: 380px;
  height: 400px;
  margin: 0 10px;
  border-radius: 12px;
  box-shadow: 0 6px 6px #e1e1e1;
  background-color: #FFFFFF;
  .card-pic {
    display: block;
    width: 100%;
    height: 240px;
    padding-top: 80px;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 0 0 #e1e1e1;
    background: #804040;
    text-align: center;
    .card-icon {
      width: 72px;
      height: 72px;
    }
    .card-title {
      margin-top: 8px;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: 600;
      text-shadow: 0 4px 4px #000000;
    }
  }
  .card-content {
    width: 100%;
    height: 160px;
    .info-list {
      width: 100%;
      padding: 30px 20px 20px 20px;
      font-size: 14px;
      line-height: 36px;
      color: #000;
      .list-item {
        width: 100%;
        display: flex;
        align-items: center;
        .item-date {
          margin-right: 6px;
          color: #A4A0A0;
        }
        .item-title {
          width: 288px;
        }
      }
    }
  }
}
</style>
