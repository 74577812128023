<template>
  <router-link
    class="case-card"
    :to="{ name: 'CaseDetail', query: { id: data.id } }"
  >
    <img class="card-pic" :src="data.img" />
    <div class="card-content">
      <div class="content-title">{{ data.title }}</div>
      <div class="in-four-line content-desc">{{ data.desc }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CaseCard',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.case-card {
  width: 380px;
  border-radius: 12px;
  box-shadow: 0 6px 6px #e1e1e1;
  background-color: #FFFFFF;
  .card-pic {
    display: block;
    width: 100%;
    height: 180px;
    border-radius: 12px 12px 0 0;
  }
  .card-content {
    width: 100%;
    height: 184px;
    padding: 16px 30px;
    .content-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
    }
    .content-desc {
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      color: #666666;
      line-height: 24px;
      text-align: justify;
    }
  }
}
</style>
