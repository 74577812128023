<template>
  <div class="resolution-card">
    <img class="card-img" :src="data.img"/>
    <router-link
      :to="data.link"
      class="card-content"
    >
      <img class="card-icon" :src="data.icon" />
      <h3 class="in-one-line card-title">{{ data.subTitle }}</h3>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ResolutionCard',
  props: {
    data: {
      type: Object,
      default() {
        return {
          title: '',
          icon: '',
          img: '',
          link: '',
          router: ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.resolution-card {
  position: relative;
  height: 240px;
  width: 380px;
  border-radius: 10px;
  background: #ccc;
  overflow: hidden;
  text-align: center;
  &:hover {
    background: rgba(255, 167, 61, 0.5);
    .card-img {
      opacity: 1;
    }
  }
  .card-img {
    width: 100%;
    height: 100%;
    opacity: .6;
  }
  .card-content {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .card-icon {
      width: 72px;
      height: 72px;
    }
    .card-title {
      width: 280px;
      margin-top: 8px;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: 600;
      text-shadow: 0 4px 4px #000000;
    }
  }
}
</style>
