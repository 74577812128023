<template>
  <router-link :to="{ name: detailRouter, query: { id: data.id } }" class="search-product-item">
    <div class="item-icon">
      <img v-lazy="data.icon" />
    </div>
    <div class="item-content">
      <div class="content-title">{{ data.title }}</div>
      <div class="in-two-line content-desc" :title="data.description">{{ data.description }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'SearchProductItem',
  props: {
    data: {
      type: Object,
      required: true
    },
    detailRouter: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.search-product-item {
  position: relative;
  width: 830px;
  height: 120px;
  padding: 16px;
  color: #E1E1E1;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  transition: all .15s ease-in-out;
  &:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, .15);
  }
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 4px;
    width: calc(100% - 8px);
    height: 0;
    border-top: 1px solid rgba(255, 255, 255, .05);
  }
  &:last-child {
    &:after {
      border-top: none;
    }
  }
  .item-icon {
    flex: none;
    width: 80px;
    height: 80px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .item-content {
    .content-title {
      line-height: 1.5;
      font-size: 18px;
      font-weight: 600;
    }
    .content-desc {
      margin-top: 12px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
