<template>
  <div class="page-content home-page">
    <MainBanner ref="mainBanner" :data="bannerData" v-loading="bannerLoading" />
    <div class="main-content">
      <DisplaySection
        title="数字化解决方案"
        desc="直击行业痛点，提供丰富的场景化解决方案，快速满足企业对多场景的业务需求"
      >
        <ResolutionBanner ref="resolutionBanner" :data="resolutionData" />
      </DisplaySection>
      <DisplaySection
        class="even"
        title="零方资讯"
        desc="及时获取覆盖全场景的数智化新零售方案的前沿信息"
      >
        <div class="info-card-list" v-loading="infoLoading">
          <InfoCard
            v-for="item in newsData"
            :key="item.id"
            :option="item"
            :list="infoListData[item.id]"
          />
        </div>
      </DisplaySection>
      <DisplaySection
        class="water-print"
        title="客户案例"
        desc="零方致力于成为零售企业最信赖的数智化服务品牌"
      >
        <HomeCaseBanner ref="caseBanner" :data="caseData" />
<!--        <div class="case-card-list" v-loading="caseLoading">-->
<!--          <CaseCard-->
<!--            v-for="item in caseListData"-->
<!--            :data="item"-->
<!--            :key="item.id"-->
<!--          />-->
<!--        </div>-->
      </DisplaySection>
      <section class="statistic-section">
        <div class="statistic-list">
          <div
            class="list-item"
            v-for="item in statisticData"
            :key="item.name"
          >
            <div class="item-number">
              <img v-lazy="item.img" class="number-img" />
              <div class="number-unit">{{ item.unit }}</div>
            </div>
            <div class="item-desc">{{ item.desc }}</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainBanner from '../components/HomeMainBanner'
import DisplaySection from '../components/HomeDisplaySection'
import ResolutionBanner from '../components/HomeResolutionBanner'
import HomeCaseBanner from '../components/HomeCaseBanner'
import InfoCard from '../components/HomeInfoCard'
import { description, keywords } from '../data/meta'

export default {
  name: 'Home',
  metaInfo: {
    title: '零方科技 | 智能连锁零售方案',
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'keywords',
        content: keywords
      }
    ]
  },
  components: {
    MainBanner,
    DisplaySection,
    ResolutionBanner,
    HomeCaseBanner,
    InfoCard
  },
  data() {
    return {
      bannerLoading: false,
      bannerData: [],
      infoLoading: false,
      caseLoading: false,
      newTypeData: [],
      infoListData: {},
      // caseListData: [],
      statisticData: [
        {
          name: 'data',
          img: require('../assets/img/pic_n1.jpg'),
          unit: '亿+',
          desc: '零售云日均服务量'
        },
        {
          name: 'server',
          img: require('../assets/img/pic_n2.jpg'),
          unit: '亿+',
          desc: '月均服务人次'
        },
        {
          name: 'store',
          img: require('../assets/img/pic_n3.jpg'),
          unit: '万+',
          desc: '服务商户数，覆盖20+省市'
        }
      ]
    }
  },
  inject: ['case', 'resolution', 'news', 'ready'],
  computed: {
    resolutionData() {
      return this.resolution()
    },
    allReady() {
      return this.ready()
    },
    newsData() {
      return this.news()
    },
    caseData() {
      return this.case()
    }
  },
  watch: {
    allReady: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.state) {
          this.$nextTick(() => {
            this.$refs.resolutionBanner.init()
            this.$refs.caseBanner.init()
          })
          this.newsData.forEach((item, index) => {
            this.getInfoList(item.id, index)
          })
        }
      }
    }
  },
  methods: {
    getBannerData() {
      const self = this
      self.bannerLoading = true
      axios.get('/api/Open/GetBannerList')
        .then(response => {
          self.bannerLoading = false
          response = response.data
          if (response.Code === '0') {
            self.bannerData = self.dealBannerData(response.Data)
            self.$nextTick(() => {
              self.$refs.mainBanner.init()
            })
          }
        })
        .catch(error => {
          self.bannerLoading = false
          console.log(error)
        })
    },
    dealBannerData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          title: item.Title,
          desc: item.Desc,
          img: item.Pic,
          link: item.Jump
        })
      })
      return result
    },
    getInfoList(id) {
      const self = this
      self.infoLoading = true
      axios.get('/api/Open/GetNewsList', {
        params: {
          newsTypeId: id,
          pageIndex: 1,
          pageSize: 3
        }
      })
        .then(response => {
          self.infoLoading = false
          response = response.data
          if (response.Code === '0') {
            self.$set(self.infoListData, id, self.dealInfoListData(response.Data.List))
          }
        })
        .catch(error => {
          self.infoLoading = false
          console.log(error)
        })
    },
    dealInfoListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          date: this.getFormatDate(item.CreateTime),
          title: item.Title,
          img: item.Cover
        })
      })
      return result
    },
    getFormatDate(dateString) {
      const date = new Date(dateString)
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day}`
    }
    // getCaseList() {
    //   const self = this
    //   self.caseLoading = true
    //   axios.get('/api/Open/GetCaseList', {
    //     params: {
    //       pageIndex: 1,
    //       pageSize: 3
    //     }
    //   })
    //     .then(response => {
    //       self.caseLoading = false
    //       response = response.data
    //       if (response.Code === '0') {
    //         self.caseListData = self.dealCaseListData(response.Data.List)
    //       }
    //     })
    //     .catch(error => {
    //       self.caseLoading = false
    //       console.log(error)
    //     })
    // },
    // dealCaseListData(data) {
    //   const result = []
    //   data.forEach(item => {
    //     result.push({
    //       id: item.Id,
    //       title: item.Name,
    //       desc: item.Summary,
    //       img: item.Pic
    //     })
    //   })
    //   return result
    // }
  },
  created() {
    this.getBannerData()
    // this.getCaseList()
  }
}
</script>
<style lang="less" scoped>
.page-content {
  width: 100%;
  .main-content {
    width: 100%;
    .info-card-list {
      width: 1200px;
      display: flex;
      align-items: center;
    }
    .case-card-list {
      width: 1200px;
      display: flex;
      align-items: center;
    }
    .even {
      background-color: #F8F8F8;
    }
    .water-print {
      width: 100%;
      overflow: hidden;
      background-image: url("../assets/img/pic_8.png");
      background-position: left bottom;
      background-repeat: no-repeat;
      min-width: 1200px;
    }
    .statistic-section {
      width: 100%;
      height: 200px;
      padding-top: 40px;
      background-image: url("../assets/img/pic9.jpg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #FFFFFF;
      .statistic-list {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list-item {
          width: 160px;
          .item-number {
            display: flex;
            .number-img {
              width: 100px;
              height: 100px;
            }
            .number-unit {
              margin-top: 60px;
              font-size: 16px;
            }
          }
          .item-desc {
            margin-top: -10px;
            margin-left: 12px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
