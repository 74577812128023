<template>
  <div class="search-page">
    <section class="search-section">
      <div class="search-box">
        <div class="search-input">
          <input type="text" placeholder="输入关键词搜索" v-model="searchKeyword" @keyup.enter.stop="searchData">
        </div>
        <button class="search-btn" @click="searchData">
          <span>站内搜索</span>
          <i class="icon-search"/>
        </button>
      </div>
      <div class="search-content">
        <ul class="nav-list">
          <li class="list-item"
              :class="{'list-item--active': currentSearchType === item.value}"
              v-for="item in searchTypeList"
              :key="item.value"
              @click="switchTypeAndSearch(item)"
          >
            {{ item.label }}
          </li>
        </ul>
        <div class="content-area">
          <div v-loading="isLoadingInfo || isLoadingProduct || isLoadingResolution || isLoadingCase">
            <div class="info-content" v-if="currentSearchType === 'info'">
              <SearchInfoItem
                mode="dark"
                v-for="item in currentData"
                :key="item.id"
                :data="item"
              />
            </div>
            <div class="product-content" v-else-if="currentSearchType === 'product'">
              <SearchProductItem
                v-for="item in currentData"
                :key="'product' + item.id"
                :data="item"
                detail-router="ProductDescription"
              />
            </div>
            <div class="resolution-content" v-else-if="currentSearchType === 'resolution'">
              <SearchProductItem
                v-for="item in currentData"
                :key="'resolution' + item.id"
                :data="item"
                detail-router="ResolutionDescription"
              />
            </div>
            <div class="case-content" v-else-if="currentSearchType === 'case'">
              <SearchCaseItem
                v-for="item in currentData"
                :key="'case' + item.id"
                :data="item"
                detail-router="CaseDetail"
              />
            </div>
            <div class="placeholder-content" v-if="!currentData.length">暂无搜索结果</div>
          </div>
          <div class="list-pagination" v-if="pagination.total > 5">
            <el-pagination
              layout="prev, pager, next"
              :current-page.sync="pagination.current"
              :total="pagination.total"
              :page-size="pagination.pageSize"
              @current-change="paginationChange"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import SearchInfoItem from '../components/SearchInfoItem'
import SearchProductItem from '../components/SearchProductItem'
import SearchCaseItem from '../components/SearchCaseItem'

export default {
  name: 'Search',
  components: {
    SearchInfoItem,
    SearchProductItem,
    SearchCaseItem
  },
  data() {
    return {
      searchKeyword: '',
      currentSearchType: 'info',
      searchTypeList: Object.freeze([
        { label: '资讯', value: 'info' },
        { label: '产品', value: 'product' },
        { label: '解决方案', value: 'resolution' },
        { label: '客户案例', value: 'case' }
      ]),
      isLoadingInfo: false,
      isLoadingProduct: false,
      isLoadingResolution: false,
      isLoadingCase: false,
      currentData: [],
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0
      }
    }
  },
  methods: {
    searchData() {
      this.isLoadingInfo = false
      this.isLoadingProduct = false
      this.isLoadingResolution = false
      this.isLoadingCase = false
      switch (this.currentSearchType) {
        case 'info':
          this.getInfoList()
          break
        case 'product':
          this.getProductList()
          break
        case 'resolution':
          this.getResolutionList()
          break
        case 'case':
          this.getCaseList()
          break
      }
    },
    switchTypeAndSearch(item) {
      this.currentSearchType = item.value
      this.pagination = {
        current: 1,
        pageSize: 5,
        total: 0
      }
      this.searchData()
    },
    getInfoList() {
      const self = this
      self.isLoadingInfo = true
      const params = {
        pageIndex: self.pagination.current,
        pageSize: self.pagination.pageSize
      }
      if (this.searchKeyword) {
        params.key = this.searchKeyword
      }
      axios.get('/api/Open/GetNewsList', { params })
        .then(response => {
          self.isLoadingInfo = false
          response = response.data
          if (response.Code === '0') {
            self.currentData = self.dealInfoListData(response.Data.List) || []
            self.pagination.total = response.Data.TotalCnt
          }
        })
        .catch(error => {
          self.isLoadingInfo = false
          console.log(error)
        })
    },
    dealInfoListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          title: item.Title,
          desc: item.Desc,
          date: this.getFormatDate(item.CreateTime),
          img: item.Cover
        })
      })
      return result
    },
    getFormatDate(dateString) {
      if (!dateString) {
        return ''
      }
      return dateString.split(' ')[0]
    },
    paginationChange(current) {
      this.pagination.current = current
      this.searchData()
    },
    getProductList() {
      const self = this
      const params = {
        pageIndex: self.pagination.current,
        pageSize: 5,
        productType: 2
      }
      if (this.searchKeyword) {
        params.key = this.searchKeyword
      }
      self.isLoadingProduct = true
      axios.get('/api/Open/GetProductList', { params })
        .then(response => {
          self.isLoadingProduct = false
          response = response.data
          if (response.Code === '0') {
            self.currentData = self.dealProductListData(response.Data.List)
            self.pagination.total = response.Data.TotalCnt
          }
        })
        .catch(error => {
          self.isLoadingProduct = false
          console.log(error)
        })
    },
    dealProductListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          icon: item.Icon,
          title: item.ShortName,
          description: item.Summary
        })
      })
      return result
    },
    getCaseList() {
      const self = this
      const params = {
        pageIndex: self.pagination.current,
        pageSize: 5
      }
      if (this.searchKeyword) {
        params.key = this.searchKeyword
      }
      self.isLoadingCase = true
      axios.get('/api/Open/GetCaseList', { params })
        .then(response => {
          self.isLoadingCase = false
          response = response.data
          if (response.Code === '0') {
            self.currentData = self.dealCaseListData(response.Data.List)
            self.pagination.total = response.Data.TotalCnt
          }
        })
        .catch(error => {
          self.isLoadingCase = false
          console.log(error)
        })
    },
    dealCaseListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          icon: item.Pic,
          title: item.Name,
          description: item.Summary
        })
      })
      return result
    },
    getResolutionList() {
      const self = this
      const params = {
        pageIndex: self.pagination.current,
        pageSize: 5,
        productType: 1
      }
      if (this.searchKeyword) {
        params.key = this.searchKeyword
      }
      self.isLoadingResolution = true
      axios.get('/api/Open/GetProductList', { params })
        .then(response => {
          self.isLoadingResolution = false
          response = response.data
          if (response.Code === '0') {
            self.currentData = self.dealProductListData(response.Data.List)
            self.pagination.total = response.Data.TotalCnt
          }
        })
        .catch(error => {
          self.isLoadingResolution = false
          console.log(error)
        })
    },
    getCurrentSearchType() {
      const fromPageName = this.$route.params.from
      switch (fromPageName) {
        case 'ResolutionDescription':
          this.currentSearchType = 'resolution'
          break
        case 'ResolutionDetail':
          this.currentSearchType = 'resolution'
          break
        case 'ProductDescription':
          this.currentSearchType = 'product'
          break
        case 'ProductDetail':
          this.currentSearchType = 'product'
          break
        case 'Case':
          this.currentSearchType = 'case'
          break
        case 'CaseDetail':
          this.currentSearchType = 'case'
          break
        default:
          this.currentSearchType = 'info'
          break
      }
    }
  },
  created() {
    this.getCurrentSearchType()
    this.searchKeyword = this.$route.params.keyword || ''
    this.searchData()
  }
}
</script>

<style lang="less" scoped>
.search-page {
  width: 100%;
  min-height: 725px;
  padding-top: 100px;
  background: url("../assets/img/plan_bg.jpg") no-repeat fixed;
  background-size: cover;
  .search-section {
    width: 1200px;
    padding: 40px 185px;
    margin: 0 auto;
    .search-box {
      width: 100%;
      height: 98px;
      padding-top: 52px;
      //background: rgba(237, 241, 244, 0.3);
      border-radius: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      .search-input {
        flex: 1;
        height: 46px;
        border-radius: 6px 0 0 6px;
        background: rgba(237, 241, 244, 0.3);
        input {
          width: 100%;
          height: 46px;
          padding: 0 20px;
          font-size: 16px;
          color: #fff;
          background: none;
          &::placeholder {
            color: #d7d7d7;
            transition: color .15s ease-in-out;
          }
          &:focus {
            &::placeholder {
              color: #888;
            }
          }
        }
      }
      .search-btn {
        width: 180px;
        height: 46px;
        border: none;
        outline: none;
        border-radius: 0 6px 6px 0;
        background-color: #FFA73D;
        color: #fff;
        cursor: pointer;
        transition: background-color .15s ease-in-out;
        font-size: 16px;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #ff8a00;
        }
        .icon-search {
          margin-left: 4px;
          margin-top: 2px;
          font-size: 18px;
        }
      }
    }
    .search-content {
      width: 100%;
      padding-top: 36px;
      .nav-list {
        width: 1200px;
        padding: 16px 0;
        display: flex;
        align-items: center;
        .list-item {
          height: 30px;
          padding: 0 16px;
          line-height: 30px;
          color: rgba(255, 255, 255, 0.4);
          font-size: 16px;
          margin-right: 20px;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            color: #FFA73D;
          }
          &--active {
            background: #FFA73D;
            color: #fff !important;
          }
        }
      }
      .info-content {
        width: 100%;
        padding: 30px 0;
      }
      .product-content {
      }
      .resolution-content {
      }
      .placeholder-content {
        min-height: 400px;
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .list-pagination {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        /deep/ .el-pagination {
          color: #FFFFFF;
        }
        /deep/ .el-pager li {
          background: none;
        }
        /deep/ .el-pagination .btn-next {
          color: #FFFFFF;
          background: none;
        }
        /deep/ .el-pagination .btn-prev {
          color: #FFFFFF;
          background: none;
        }
        /deep/ .el-pagination .btn-prev:hover {
          color: #FFA73D;
        }
        /deep/ .el-pagination .btn-next:hover {
          color: #FFA73D;
        }
        /deep/ .el-pagination button:disabled {
          color: #888888 !important;
          background: none;
        }
        /deep/ .el-pager li.active {
          color: #FFA73D;
        }
        /deep/ .el-pager li:hover {
          color: #FFA73D;
        }
      }
    }
  }
  /deep/ .el-loading-mask {
    background-color: rgba(0, 0, 0, .4);
    border-radius: 4px;
  }
}
</style>
