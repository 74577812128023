<template>
  <div class="page-content case-page">
    <div class="case-title">客户案例</div>
    <div class="user-content">
      <div
        class="user-list"
        @mouseleave="setCurrentCase()"
      >
        <div
          class="list-item"
          :class="{'list-item--active': currentIndex === index}"
          v-for="(item,index) in caseBrandListData"
          :key="item.Id"
          @mouseenter="setCurrentCase(index)"
        >
          <div class="item-content">
            <img :src="item.Pic" />
          </div>
        </div>
        <div
          class="list-item-box"
          :class="{'list-item-box--show' : showPopup}"
          :style="{ 'transform': `translateY(${Math.ceil((currentIndex + 1) / 5) * 128}px)` }"
        >
          <div class="case-list">
            <div class="case-list-item" v-for="item in currentCase" :key="item.Id">
              <router-link
                class="item-title"
                :to="{ name: 'CaseDetail', query: { id: item.Id } }"
              >
                {{ item.Name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { description, keywords } from '../data/meta'

export default {
  name: 'Case',
  metaInfo: {
    title: '零方科技 | 客户案例',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords }
    ]
  },
  inject: ['caseOrigin'],
  computed: {
    caseBrandListData() {
      return this.caseOrigin()
    }
  },
  data() {
    return {
      currentCase: [],
      currentIndex: -1,
      showPopup: false
    }
  },
  methods: {
    setCurrentCase(index) {
      if (!index) { this.showPopup = false }
      this.currentIndex = index
      this.currentCase = this.caseBrandListData[index].Cases || []
      if (this.currentCase.length) {
        this.showPopup = true
      } else {
        this.showPopup = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.case-page {
  width: 100%;
  padding: 120px 0 200px 0;
  .case-title {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 49px;
    text-align: center;
    margin: 80px 0 80px 0;
  }
  .user-content {
    width: 100%;
    .user-list {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .list-item {
        width: 20%;
        height: 128px;
        padding-bottom: 8px;
        .item-content {
          width: 100%;
          height: 100%;
          border: 1px solid transparent;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            border-color: #e6a23c;
            img {
              transform: scale(1.05);
            }
          }
          img {
            max-width: 80%;
            max-height: 80%;
            width: auto;
            height: 80px;
            transition: transform .2s ease-in-out;
          }
        }
        &--active {
          .item-content {
            border-color: #e6a23c;
            opacity: 1;
            img {
              transform: scale(1.02);
            }
          }
        }
      }
      .list-item-box {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 1200px;
        padding: 30px;
        box-shadow: 0 2px 16px rgba(0, 0, 0, .15);
        border-radius: 8px;
        background: rgba(255, 255, 255, .9);
        &--show {
          display: block;
        }
        .case-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .case-list-item {
            width: 20%;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-title {
              width: 100%;
              height: 100%;
              padding: 16px 10px;
              border-radius: 8px;
              border: 1px solid transparent;
              text-align: center;
              color: #333;
              cursor: pointer;
              transition: all .3s ease-in-out;
              &:hover {
                background: rgba(230, 162, 60, 0.2);
                color: #FFA73D;
                border-color: #e6a23c;
              }
            }
          }
        }
      }
    }
  }
  .case-content {
    position: relative;
    width: 100%;
    background-color: #F8F8F8;
    &:before {
      content: '';
      position: absolute;
      top: -31px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-bottom: 16px solid #F8F8F8;
      border-left: 16px solid transparent;
      border-top: 16px solid transparent;
      border-right: 16px solid transparent;
    }

  }
}
</style>
