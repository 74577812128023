<template>
  <div class="case-banner swiper-container">
    <div class="swiper-wrapper">
      <HomeCaseCard
        class="swiper-slide"
        v-for="item in data"
        :key="item.title"
        :data="item"
      />
    </div>
    <div class="custom-swiper-button custom-swiper-button-prev">
      <i class="icon icon-prev"/>
    </div>
    <div class="custom-swiper-button custom-swiper-button-next">
      <i class="icon icon-next"/>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import HomeCaseCard from './HomeCaseCard'

export default {
  name: 'ResolutionBanner',
  props: {
    data: {
      type: Array
    }
  },
  components: {
    HomeCaseCard
  },
  data() {
    return {
      swiper: null
    }
  },
  methods: {
    init() {
      this.swiper = new Swiper('.case-banner', {
        autoplay: {
          delay: 8000
        },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        navigation: {
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-container {
  width: 1200px;
  height: 390px;
  padding: 0 10px;
  .custom-swiper-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    border: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .2);
    color: #fff;
    transition-duration: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, .35);
    }
    .icon {
      font-size: 12px;
    }
  }
  .custom-swiper-button-prev {
    left: 20px;
  }
  .custom-swiper-button-next {
    right: 20px;
  }
  .swiper-wrapper {
    .swiper-slide {
      width: 380px;
      height: 380px;
    }
  }
}
</style>
